.loader {
  opacity: 1;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.loader.gradient {
  background: linear-gradient(122deg, #400095 6.65%, #434cb1 79.64%);
  z-index: 2;
}

.loader.white {
  border: 2px solid transparent;
  border-radius: 16px;
  background: white;
  z-index: 1;
}

.loader .spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 48px;
  height: 48px;
}

.loader .spinner .dot {
  width: 8px;
  height: 8px;
  border-radius: 50%;
  position: absolute;
  animation: App-logo-spin-background-color 0.815s ease-out infinite;
}

.loader .spinner .dot:nth-child(1) { top: 0; left: 50%; transform: translateX(-50%); animation-delay: -800ms; }
.loader .spinner .dot:nth-child(2) { top: 20%; left: 80%; transform: translate(-50%, -50%); animation-delay: -700ms; }
.loader .spinner .dot:nth-child(3) { top: 50%; right: 0; transform: translateY(-50%); animation-delay: -600ms; }
.loader .spinner .dot:nth-child(4) { top: 80%; left: 80%; transform: translate(-50%, -50%); animation-delay: -500ms; }
.loader .spinner .dot:nth-child(5) { bottom: 0; left: 50%; transform: translateX(-50%); animation-delay: -400ms; }
.loader .spinner .dot:nth-child(6) { top: 80%; left: 20%; transform: translate(-50%, -50%); animation-delay: -300ms; }
.loader .spinner .dot:nth-child(7) { top: 50%; left: 0; transform: translateY(-50%); animation-delay: -200ms; }
.loader .spinner .dot:nth-child(8) { top: 20%; left: 20%; transform: translate(-50%, -50%); animation-delay: -100ms; }

.hidden {
  pointer-events: none;
  animation: fadeOut 300ms ease-out forwards;
}

@keyframes App-logo-spin-background-color {
  0%,
  10% {
    background-color: #2d2d2d;
  }
  11%,
  100% {
    background-color: #d9d9d9;
  }
}

@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
